import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { StlcSharedModule } from '@stlc/angular/shared';

import { HomepageComponent } from './components';

@NgModule({
    imports: [CommonModule, StlcSharedModule],
    declarations: [HomepageComponent],
    exports: [HomepageComponent],
})
export class PracticeUiModule {}
