import { Component, Input } from '@angular/core';

@Component({
    selector: 'practice-ui-homepage',
    templateUrl: './homepage.component.html',
    styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent {
    @Input()
    type: 'batting' | 'pitching' = 'pitching';

    @Input()
    summary?: any;
}
