<ng-container *ngIf="summary">
    <div class="flex flex-row flex-wrap justify-center items-center">
        <div class="flex flex-col border border-gray-100 w-28 m-1 rounded" #swingsDiv>
            <div class="text-center text-base p-2 font-bold border-b border-gray-50">
                {{ type === 'batting' ? 'Swings' : 'Pitches' }}
            </div>
            <div class="flex justify-center items-center text-4xl h-16 p-2">{{ summary.ballTrackingEvents }}</div>
        </div>
        <div
            class="border border-gray-100 w-28 h-[103px] m-1 rounded"
            *ngIf="summary.thumbnail && summary.thumbnailBucket"
        >
            <img
                class="w-full h-full object-cover aspect-s"
                stlcProtectedSrc="/img/practice/thumbnail?bucket={{ summary.thumbnailBucket }}&filename={{
                    summary.thumbnail
                }}"
                [alt]="summary.date"
            />
        </div>
        <div class="flex flex-col border border-gray-100 w-28 m-1 rounded">
            <div class="text-center text-base p-2 font-bold border-b border-gray-50">Videos</div>
            <div class="flex justify-center items-center text-4xl h-16 p-2">
                {{ summary.ballTrackingEventsWithVideo }}
            </div>
        </div>
        <div class="flex flex-col border border-gray-100 w-28 m-1 rounded">
            <div class="text-center text-base p-2 font-bold border-b border-gray-50">Angles</div>
            <div class="flex justify-center items-center text-4xl h-16 p-2">{{ summary.totalVideoAngles }}</div>
        </div>
    </div>
</ng-container>
